@import "variables.scss";
@import "mixins.scss";

body {
	color: $white;
	background: $red;
}

.login h1 a {
	height: 100px !important;
}

.login form {
	border: none;
	box-shadow: none;
	background: $red;
}

.login label {
	color: $white;
	font-size: 12px;
}

#nav, #backtoblog {
	display: none;
}

.login input[type=text], .login input[type=password], #login input[type=tel] {
	background: $red;
	border: none;
	border-bottom: 1px solid $white;
	color: $white;
	box-shadow: none;
	font-weight: normal;
	font-family: 'Courier', 'Courier New', sans-serif;
	
	&:focus {
		outline: none;
		box-shadow: none;
	}
}

 input[type=checkbox] {
	border: 1px solid $white;
	background: $red;
	
	&:checked:before {
		color: $red;
	}
	
	&:focus {
		border: 1px solid $white;
		box-shadow: none;
	}
}

#wp-submit, #submit  {
	background: $red;
	color: $white;
	border: 1px solid $white;
	border-radius: 4px;
	text-shadow: none;
	font-size: 12px;
	box-shadow: none;
	margin-top: 20px;
	width: 100%;
	
	&:hover {
		color: $red;
		background: $white;
	}
}

.wp-core-ui .button-group.button-large .button, .wp-core-ui .button.button-large {
	height: 40px;
}

.login #login_error {
	border: none;
	background: $red;
	color: $white;
	
	a {
		color: $white;
		
		&:hover {
			color: $red;
		}
	}
}

.login .message {
	border: none;
	background: $red;
	color: $white;
	box-shadow: none;
	text-align: center;

}

a:focus {
	box-shadow: none;
}

.cc_banner-wrapper {
	display: none !important; //Hide cookies notice on backend
}

.itsec-backup-methods {
	border: none !important;
	display: none !important;
	
	ul {
		list-style: none !important;
		padding: 0;
		margin: 0 !important;
	}
	
	a {
		color: $white;
		text-decoration: none !important;
	}
}